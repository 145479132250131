import { Component, Show, createSignal, createEffect } from "solid-js";
import { Img } from "~/components/image";
import { arrowUturnLeft } from "solid-heroicons/outline";
import { Icon } from "solid-heroicons";
import cvc1 from "../../assets/cvc/cvc1.jpg";
import cvc2 from "../../assets/cvc/cvc2.jpg";
import cvc3 from "../../assets/cvc/cvc3.jpg";
import cvc4 from "../../assets/cvc/cvc4.jpg";
import cvc5 from "../../assets/cvc/cvc5.jpg";
import cvc6 from "../../assets/cvc/cvc6.jpg";
import cvc7 from "../../assets/cvc/cvc7.jpg";
import cvc8 from "../../assets/cvc/cvc8.jpg";
import cvc9 from "../../assets/cvc/cvc9.jpg";
import cvc10 from "../../assets/cvc/cvc10.jpg";
import "./coreValueCard.css";

const cvc: Record<string, any> = {
  cvc1: {
    img: cvc1,
    bgColour: "f7941d",
    textColour: "FFFFFF",
    description: "Deliver WOW through service",
  },
  cvc2: {
    img: cvc2,
    bgColour: "652269",
    textColour: "FFFFFF",
    description: "Be real and live in integrity",
  },
  cvc3: {
    img: cvc3,
    bgColour: "ffde15",
    textColour: "EA252B",
    description: "Be differet, have fun and deliver happiness",
  },
  cvc4: {
    img: cvc4,
    bgColour: "12a89d",
    textColour: "E6EEBD",
    description: "Build a positive team and family environment",
  },
  cvc5: {
    img: cvc5,
    bgColour: "25aae2",
    textColour: "2C2C2D",
    description: "Be passionate, committed and driven",
  },
  cvc6: {
    img: cvc6,
    bgColour: "e21562",
    textColour: "000000",
    description: "Inspire innovation and drive change",
  },
  cvc7: {
    img: cvc7,
    bgColour: "d4de41",
    textColour: "45444B",
    description: "Be humble",
  },
  cvc8: {
    img: cvc8,
    bgColour: "84205e",
    textColour: "DBE4DC",
    description: "Be creative, open minded and share ideas",
  },
  cvc9: {
    img: cvc9,
    bgColour: "61a342",
    textColour: "FFFFFF",
    description: "Deliver transparent, open and honest communication",
  },
  cvc10: {
    img: cvc10,
    bgColour: "92d9ef",
    textColour: "000000",
    description: "Love learning and pursue growth and development",
  },
};

type CVCProps = {
  val: number | string;
  flippable?: boolean;
  forceFlip?: () => boolean;
  class?: string;
  toName?: string;
  fromName?: string;
  message?: string;
};

export const CoreValueCard: Component<CVCProps> = (props) => {
  const [isFlipped, setIsFlipped] = createSignal(false);
  const card = () => cvc[`cvc${props.val}`];
  createEffect(() => {
    if (props.forceFlip) {
      setIsFlipped(props.forceFlip());
    }
  });

  return (
    <>
      <div
        class="flip-card aspect-square max-w-lg print:hidden relative w-full"
        classList={{ [`${props.class}`]: !!props.class }}
      >
        <div
          class="flip-card-inner transform "
          classList={{ "[transform:rotateY(180deg)]": isFlipped() }}
        >
          <div class="flip-card-front">
            <Img src={card().img} layout="fullWidth" alt={card().description} />
          </div>
          <div
            class="flip-card-back p-4 flex flex-col  text-sm md:text-base"
            style={{
              "background-color": `#${card().bgColour}`,
              color: `#${card().textColour}`,
            }}
          >
            <p>
              To: {props.toName != "" ? props.toName : "Someone"} <br />
              {props.message != "" ? "Message:" : ""}
            </p>
            <Show
              when={props.message && props.message !== ""}
              fallback={
                <div class="h-full flex items-center justify-center px-4">
                  <p class="text-center text-lg">
                    You have exemplified this Core Value in a way that has
                    Moved, Insipired, & WOW'ed&nbsp;me!
                    <br />
                    <span class="text-xl font-bold">
                      Keep up the awesome work!
                    </span>
                  </p>
                </div>
              }
            >
              <p class="px-8 overflow-y-auto break-words">{props.message}</p>
            </Show>

            <p class="mt-auto">
              From: {props.fromName != "" ? props.fromName : "Anonymous"}
            </p>
          </div>
        </div>
        <Show when={props.flippable}>
          <button
            type="button"
            class="flip_button absolute -top-4 -right-4 p-2 rounded-full border-3 border-white"
            onClick={() => setIsFlipped(!isFlipped())}
            style={{
              "background-color": `#${card().bgColour}`,
              color: `#${card().textColour}`,
            }}
          >
            <Icon
              stroke-width={3}
              path={arrowUturnLeft}
              class="w-8 h-8 -rotate-45"
            />
          </button>
        </Show>
      </div>
      {/* PRINT ONLY  */}
      <div class="hidden print:block max-w-sm">
        <Img src={card().img} layout="fullWidth" alt={card().description} />
      </div>

      <div
        class="cvc_card_back hidden print:flex aspect-square max-w-sm flex-col gap-2  p-4 text-sm md:text-base"
        style={{
          "background-color": `#${card().bgColour}`,
          color: `#${card().textColour}`,
        }}
      >
        <p>
          To: {props.toName != "" ? props.toName : "Someone"} <br />
          {props.message != "" ? "Message:" : ""}
        </p>
        <Show
          when={props.message && props.message !== ""}
          fallback={
            <div class="h-full flex items-center justify-center px-4">
              <p class="text-center text-lg">
                You have exemplified this Core Value in a way that has Moved,
                Insipired, & WOW'ed&nbsp;me!
                <br />
                <span class="text-xl font-bold">Keep up the awesome work!</span>
              </p>
            </div>
          }
        >
          <p class="px-8 print:px-6">{props.message}</p>
        </Show>

        <p class="mt-auto">
          From: {props.fromName != "" ? props.fromName : "Anonymous"}
        </p>
      </div>
      {/* END OF PRINT ONLY */}
    </>
  );
};
